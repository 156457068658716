<template>
  <PageLayoutWrapper
    page="index"
    class="outline-none"
    :content="homePage"
  >
    <RichContent
      class="main-content"
      :content="homePage.content"
    />
  </PageLayoutWrapper>
</template>

<script>
import PageLayoutWrapper from '@/components/PageLayoutWrapper'
import pageLayout from '@/mixins/pageLayoutMixin'
import RichContent from '@/components/RichContent'
import { metaTags, metaTitle } from '@/utils/meta'
import useMeta from '@/composables/useMeta'

export default {
  components: { PageLayoutWrapper, RichContent },
  mixins: [pageLayout],
  setup() {
    const { catalogWideMetaTitle } = useMeta()
    return { catalogWideMetaTitle }
  },
  async asyncData({ params, $axios, $bugsnag, redirect, store }) {
    try {
      const homePage = await $axios.$get(
        `/ca/${store.state.settings.school}/catalogPages/${
          store.state.settings.activeCatalog
        }/home`
      )
      return {
        homePage,
      }
    } catch (e) {
      $bugsnag.notify(e)
      redirect('/404')
    }
  },
  head() {
    return {
      ...metaTitle(this.homePage.metafields, this.catalogWideMetaTitle),
      meta: metaTags(this.homePage.metafields),
    }
  },
}
</script>
